import React from 'react'
import logoKebijakan from '../../assets/Image/logo-bening1.png'
import Accordion  from '../../components/Accordion'
import './stylesheet.css'
import $, { event } from 'jquery'

export default function KebijakanPrivasi() {

    return (

    <div>
        <header id="header" className="sticky-top">
           
            <nav className="primary-menu navbar navbar-expand-lg navbar-dropdown-dark">
                <div className="container-fluid">
                    
                    <button id="sidebarCollapse" className="navbar-toggler d-block d-md-none"
                        type="button"><span></span><span className="w-75"></span><span className="w-50"></span></button>

                  
                    <a className="logoKebijakan ml-md-3" href="/" title="kebijakan Privasi"> <img src={logoKebijakan}
                            alt="" /> </a>
                  
                  

                    
                    {/* <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse"
                        data-target="#header-nav"><span></span><span></span><span></span></button> */}

                    <div id="header-nav" className="collapse navbar-collapse justify-content-end">
                    </div>
                    <ul className="social-icons social-icons-sm ml-lg-2 mr-2">
                        <li className="social-icons-twitter"><a data-toggle="tooltip"
                                href="/" target="_blank" title=""
                                data-original-title="Twitter"><i className="fab fa-twitter"></i></a></li>
                        <li className="social-icons-facebook"><a data-toggle="tooltip"
                                href="/" target="_blank" title=""
                                data-original-title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                        <li className="social-icons-dribbble"><a data-toggle="tooltip"
                                href="/" target="_blank" title=""
                                data-original-title="Dribbble"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </nav>
           
        </header>

        <div id="content" role="main">
      
            <div className="idocs-navigation bg-light">
                <ul className="nav flex-column ">
                    <li className="nav-item"><a className="nav-link active" href="#start">KEBIJAKAN PRIVASI</a>
                        <ul className="nav flex-column">
                            <li className="nav-item"><a className="nav-link" href="#pendahuluan">Pendahuluan</a></li>
                            <li className="nav-item"><a className="nav-link" href="#benings">Kapan bening’s skincare akan mengumpulkan data pribadi?</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="#data">Data pribadi apa yang akan di kumpulkan oleh bening's skincare?</a></li>
                            <li className="nav-item"><a className="nav-link" href="#pengumpulan-data-lain">Pengumpulan data lainnya</a></li>
                            <li className="nav-item"><a className="nav-link" href="#perubahan-kebijakan">Perubahan atas kebijakan privasi</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="#Cookies">Cookies</a></li>
                            <li className="nav-item"><a className="nav-link" href="#informasi">Bagaimana kami menggunakan Informasi yang anda berikan kepada kami ?</a></li>
                            <li className="nav-item"><a className="nav-link" href="#melindungi">Bagaimana Bening's skincare melindungi dan menarik informasi Pelanggan ?</a></li>
                            <li className="nav-item"><a className="nav-link" href="#Informasi-anak">Informasi tentang anak anak</a></li>
                            <li className="nav-item"><a className="nav-link" href="#Pengakuan">Pengakuan dan Persetujuan</a></li>
                        </ul>
                    </li>
                    <li className="nav-item"><a className="nav-link" href="#faq">FAQ</a></li>
                    
                </ul>
            </div>
            
            <div className="idocs-content">
                <div className="container">

                    <section id="start">
                        <h1>KEBIJAKAN PRIVASI</h1>
                      
                        <p className="lead"></p>
                        <hr/>
                        <div className="row">
                            <div className="col-sm-6 col-lg-4">
                                <ul className="list-unstyled">
                                    <li><strong>Version:</strong> 1.0</li>
                                    <li><strong>Author:</strong> <a href="https://www.digital.flowmotion.co.id"
                                    >Digital Flowmotion</a></li>
                                </ul>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <ul className="list-unstyled">
                                    <li><strong className="font-weight-700">Created:</strong> 18 January, 2023</li>
                                    <li><strong>Update:</strong> 20 January, 2023</li>
                                </ul>
                            </div>
                        </div>
                        <p className="alert alert-info">If you have any questions that are beyond the scope of this help
                            file, Please feel free to email via <a
                                href="mailto:care@beningspratamagrup.co.id">Item
                                Support Page</a>.</p>
                    </section>

                    <hr className="divider"/>

      
                    <section id="pendahuluan">
                        <h2>Pendahuluan</h2>
                        <ol>
                            <li><dt> PENDAHULUAN</dt>
                                <ol>
                                    <li> Selamat datang di platform Bening’s Skincare yang dioperasikan oleh PT Benings Pratama Grup. Bening’s Skincare
                                        dengan serius bertanggung jawab berdasarkan peraturan perundang-undangan tentang privasi yang berlaku ("Undang-Undang
                                        Privasi") dan berkomitmen untuk menghormati hak dan masalah privasi semua Pengguna Platform Bening’s Skincare kami
                                        ("Platform") (secara bersama-sama kami menyebut Platform dan layanan yang kami sediakan sebagaimana yang dijelaskan
                                        dalam Situs kami sebagai "Layanan"). Kami mengakui pentingnya data pribadi yang telah Anda percayakan kepada kami dan
                                        percaya bahwa merupakan tanggung jawab kami untuk mengelola, melindungi dan mengolah data pribadi Anda dengan baik.
                                        Kebijakan Privasi ini ("Kebijakan Privasi" atau "Kebijakan") dirancang untuk membantu Anda memahami bagaimana kami
                                        mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah data pribadi yang telah Anda percayakan kepada kami dan/atau
                                        kami miliki tentang Anda, baik di masa sekarang maupun di masa mendatang, serta untuk membantu Anda membuat keputusan
                                        yang tepat sebelum memberikan data pribadi Anda kepada kami.
                                    </li>
                                
                                    <li>
                                            "Data Pribadi" atau "data pribadi" berarti data, baik benar maupun tidak, tentang individu yang dapat diidentifikasi
                                            dari data tersebut, atau dari data dan informasi lainnya yang dapat atau kemungkinan dapat diakses oleh suatu
                                            organisasi. Contoh umum data pribadi dapat mencakup nama, nomor identifikasi dan informasi kontak.
                                            
                                        </li>
                                    <li>
                                        Dengan menggunakan Layanan, mendaftarkan akun pada kami, mengunjungi Platform kami, atau mengakses Layanan, Anda
                                        mengakui dan setuju bahwa Anda menerima praktik, persyaratan, dan/atau kebijakan yang diuraikan dalam Kebijakan Privasi
                                        ini, dan Anda dengan ini mengizinkan kami untuk mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah data pribadi
                                        Anda seperti yang dijelaskan di sini. APABILA ANDA TIDAK MENGIZINKAN PENGOLAHAN DATA PRIBADI ANDA SEPERTI YANG
                                        DIJELASKAN DALAM KEBIJAKAN PRIVASI INI, MOHON JANGAN MENGGUNAKAN LAYANAN KAMI ATAU MENGAKSES PLATFORM KAMI. Apabila kami
                                        mengubah Kebijakan Privasi kami, kami akan memposting perubahan tersebut atau Kebijakan Privasi yang telah diubah pada
                                        Platform kami. Kami berhak untuk mengubah Kebijakan Privasi ini setiap saat.
                                    
                                    </li>
                                    <li>
                                        Kebijakan ini berlaku sehubungan dengan pemberitahuan lain, klausul kontrak, klausul persetujuan yang berlaku terkait
                                        dengan pengumpulan, penyimpanan, penggunaan, pengungkapan dan/atau pemrosesan data pribadi Anda oleh kami dan tidak
                                        dimaksudkan untuk mengesampingkan pemberitahuan atau klausul tersebut kecuali kami nyatakan sebaliknya.
                                    </li>
                                    <li> Kebijakan ini berlaku untuk pembeli yang menggunakan Layanan kecuali secara tegas dinyatakan lain.</li>
                                </ol>
                            </li>
                        </ol>
                    </section>

                    <hr className="divider"/>
               
                    <section id="benings">
                        <h2>Kapan Bening's skincare akan mengumpulkan data pribadi?</h2>
                        
                      
                        <p className="alert alert-info mb-3">If you need more information, please visit Flowmotion site: <a
                                href="https://digital.flowmotion.co.id">https://digital.flowmotion.co.id</a></p><br/>

                                <ul>
                                   
                                    2.1 Kami akan/mungkin mengumpulkan data pribadi Anda : <br/>
                                    
                                        <ol>
                                            <li>saat Anda mendaftar dan/atau menggunakan Layanan atau Platform kami, atau membuka sebuah akun dengan kami.</li>
                                         
                                            <li>
                                                saat Anda mengisi informasi sewaktu menggunakan Layanan atau Platform kami, mengirimkan formulir apapun, termasuk,
                                                tetapi tidak terbatas pada, formulir permohonan atau formulir lainnya yang berkaitan dengan produk dan layanan kami,
                                                baik secara online maupun dalam bentuk fisik;
                                
                                            </li>
                                            <li>
                                                saat anda membuat perjanjian atau memberikan dokumen atau informasi lainnya sehubungan dengan interaksi Anda dengan
                                                kami, atau saat Anda menggunakan produk dan layanan kami
                                
                                            </li>
                                            <li>
                                                saat Anda berinteraksi dengan kami, seperti melalui sambungan telepon (yang mungkin direkam), surat, faks, pertemuan
                                                tatap muka, platform media sosial dan email, aplikasi chat seperti Whatsapp;

                                            </li>
                                            <li>saat Anda menggunakan layanan elektronik kami, atau berinteraksi dengan kami melalui aplikasi kami atau menggunakan
                                            layanan di Platform kami. Ini termasuk, dengan tidak terbatas pada, melalui cookies yang mungkin kami gunakan saat Anda
                                            berinteraksi dengan aplikasi atau situs web kami;</li>
                                            <li>saat Anda memberikan izin pada perangkat Anda untuk berbagi informasi dengan aplikasi atau Platform kami;</li>
                                            <li>saat Anda menautkan akun Bening’s Skincare Anda dengan media social Anda atau akun eksternal lainnya atau menggunakan
                                            fitur media sosial lainnya, sesuai dengan kebijakan penyedia;</li>
                                            <li>saat Anda melakukan transaksi melalui Layanan kami;</li>
                                            <li>saat Anda menyampaikan kritik dan saran atau keluhan kepada kami;</li>
                                            <li>saat Anda mendaftar untuk suatu kontes; atau</li>
                                            <li>saat Anda mengirimkan data pribadi Anda kepada kami dengan alasan apapun</li>
                                        </ol> <br/>

                                        <p>Daftar di atas tidak dimaksudkan sebagai suatu daftar yang lengkap dan hanya menetapkan beberapa contoh umum tentang
                                        kapan data pribadi Anda mungkin diambil.</p>
                                      
                                        <span>2.2</span> Kami dapat mengumpulkan data pribadi anda dari anda, afiliasi kami, pihak ketiga, dan dari sumber lainnya, termasuk
                                        namun tidak terbatas dari mitra bisnis (seperti penyedia layanan logistik atau pembayaran), biro kredit atau agensi
                                        penilai, penyedia atau mitra layanan pemasaran, program rujukan atau loyalitas, pengguna lain atas Layanan kami atau
                                        data lain yang tersedia untuk publik atau bersumber dari pemerintah. <br/><br/>
                                    
                                        <span>2.3</span> Dalam situasi tertentu, anda dapat memberikan data pribadi dari individu lain kepada kami (seperti anggota keluarga
                                        atau teman anda atau orang pada daftar kontak anda). Jika anda memberikan data pribadi mereka kepada kami, anda
                                        menyatakan dan menjamin bahwa anda telah memperoleh persetujuan atas data pribadi mereka untuk diproses sehubungan
                                        dengan Kebijakan ini.
                                   
                                </ul>
                    </section>

                    <hr className="divider"/>

                  
                    <section id="data">
                        <h2>Data pribadi apa yang akan dikumpulkan oleh bening's skincare ?</h2>
                        
                        <ul>
                            3.1 Data pribadi yang mungkin dikumpulkan Bening’s Skincare termasuk tetapi tidak terbatas pada:
                            <ol>
                                <li>Nama</li>
                                <li>Alamat Email</li>
                                <li>Tanggal lahir</li>
                                <li>Alamat tagihan</li>
                                <li>Rekening bank dan informasi pembayaran</li>
                                <li>Nomor telepon</li>
                                <li>Jenis Kelamin</li>
                                <li>Ukuran dan Bentuk Tubuh</li>
                                <li>Informasi yang dikirim oleh atau terhubung dengan perangkat(-perangkat) yang digunakan untuk mengakses Layanan atau
                                Platform kami</li>
                                <li>Informasi tentang jaringan anda, termasuk daftar kontak anda saat anda memberikan persetujuan/izin di perangkat anda, dan orang
                                serta akun yang berinteraksi dengan Anda</li>
                                <li>Foto,termasuk foto yang diambil untuk tujuan penyerahan bukti pengiriman secara elektronik, atau rekaman audio atau
                                video</li>
                                <li>Identifikasi yang dikeluarkan pemerintah dan/atau informasi lain yang diperlukan untuk uji tuntas kami, mengenal
                                pelanggan Anda, verifikasi identitas, atau tujuan pencegahan penipuan</li>
                                <li>Informasi lain apapun tentang Pengguna saat Pengguna mendaftarkan diri untuk menggunakan Layanan atau Platform kami, dan
                                saat Pengguna menggunakan Layanan atau Platform, serta informasi yang berkaitan dengan bagaimana Pengguna menggunakan
                                Layanan atau Platform kami</li>
                                <li>Informasi atau data terkait setiap pihak atau individu yang belum terdaftar pada Platform yang diberikan oleh Pengguna
                                saat menggunakan Layanan; dan</li>
                                <li>seluruh data tentang konten yang digunakan Pengguna.</li>
                            </ol><br/>

                            <span>3.2</span> Anda setuju untuk tidak menyerahkan setiap informasi yang tidak akurat atau menyesatkan kepada kami, dan anda setuju
                            untuk memberitahukan kami atas ketidakakuratan atau perubahan dari informasi tersebut. Kami memiliki hak berdasarkan
                            kebijaksanaan kami untuk mensyaratkan dokumentasi lain yang lebih lanjut untuk melakukan verifikasi atas informasi yang
                            anda berikan. <br/><br/>
                            
                            <span>3.3</span> Apabila anda mendaftarkan diri untuk menjadi pengguna dari Platform kami dengan menggunakan akun media sosial anda
                            (“Akun Media Sosial”), menghubungkan akun Bening's Skincare anda dengan Akun Media Sosial anda atau menggunakan setiap
                            fitur media sosial Bening's Skincare, kami dapat mengakses informasi tentang anda yang secara sukarela anda serahkan
                            kepada penyedia Akun Media Sosial sesuai kebijakan penyedia media sosial, dan kami akan mengatur dan menggunakan data
                            pribadi tersebut sesuai dengan Kebijakan ini sepanjang waktu. <br/><br/>

                            <span>3.4</span> Untuk melindungi keamanan data pribadi pembeli, Bening's Skincare akan menyamarkan dan/atau menyembunyikan Data Pribadi
                            pembeli untuk (i) setiap pesanan yang siap/akan dibayarkan (ii) setiap pesanan yang telah berstatus “Siap di kirim”
                            dan/atau “Dikirimkan” (iii) setiap pesanan yang ditolak oleh sistem, atau (iv) setiap pesanan yang telah diselesaikan.    
                       </ul>
                    </section>

                    <hr className="divider"/>

              
                    <section id="pengumpulan-data-lain">
                        <h2>PENGUMPULAN DATA LAINNYA</h2>
                        <p>Seperti kebanyakan situs web dan aplikasi mobile, perangkat Anda mengirimkan informasi yang mungkin mencakup data
                        mengenai Anda yang dapat dicatat oleh server web apabila Anda menggunakan Platform kami. Ini biasanya termasuk tanpa
                        terbatas, alamat Internet Protocol (IP) perangkat Anda, sistem operasi computer/ perangkat mobile, dan jenis browser,
                        jenis perangkat mobile, karakteristik perangkat mobile, unique device identifier (UDID) atau mobile equipment identifier
                        (MEID) untuk perangkat mobile anda, alamat dari situs yang merekomendasikan (apabila ada), halaman yang anda kunjungi di
                        situs web kami dan aplikasi mobile dan waktu kunjungan anda, dan kadang "cookie" (yang dapat dinonaktifkan dengan
                        menggunakan preferensi browser Anda) untuk membantu situs mengingat kunjungan Anda yang terakhir. Jika Anda log in
                        (masuk), informasi ini dikaitkan dengan akun pribadi Anda. Informasi tersebut juga disertakan dalam statistik anonim
                        untuk memungkinkan kami memahami cara pengunjung menggunakan situs kami.</p>
                        

                    </section>

                    <hr className="divider"/>

                    <section id="perubahan-kebijakan">
                        <h2>Perubahan atas kebijakan privasi</h2>
                        <p>Kami dapat mengubah Kebijakan Privasi ini untuk mencerminkan perubahan dalam kegiatan kami. Jika kami mengubah Kebijakan
                        Privasi ini, kami akan memberitahu anda melalui email atau dengan cara pemberitahuan di Situs web 1 hari sebelum
                        perubahan berlaku. Kami menghimbau anda untuk meninjau halaman ini secara berkala untuk mengetahui informasi terbaru
                        tentang bagaimana ketentuan Kebijakan Privasi ini kami berlakukan</p>
                     

                    </section>

                    <hr className="divider"/>

                    <section id="Cookies">
                        <h2>COOKIES</h2>
                        <ol><p><span>6.1</span> Sewaktu-waktu, kami atau penyedia layanan resmi kami dapat menggunakan "cookie" atau fitur lain untuk
                            memungkinkan kami mengumpulkan atau berbagi informasi sehubungan dengan penggunaan Anda atas Layanan atau Platform. Fitur
                            tersebut membantu kami memperbaiki Platform dan Layanan kami yang kami tawarkan, atau membantu kami menawarkan layanan serta
                            fitur baru. “Cookie” adalah pengenal yang disimpan dalam komputer atau perangkat mobile Anda yang merekam data
                            tentang komputer atau perangkat Anda bagaimana dan kapan Layanan atau Platform digunakan atau dikunjungi, oleh berapa banyak
                            orang, dan aktifitas lainnya di dalam Platform kami. Kami dapat menautkan informasi cookie ke data pribadi. Cookie
                            juga tertaut ke informasi mengenai item apa yang sudah Anda pilih untuk pembelian dan halaman situs yang Anda lihat.
                            Informasi ini juga digunakan untuk melacak troli belanja Anda, untuk menyampaikan konten spesifik sesuai minat Anda,
                            untuk melakukan analisa data dan untuk memantau penggunaan Layanan kami.</p>
                        
                            <p><span>6.2</span> Anda boleh menolak penggunaan cookie dengan memilih pengaturan yang sesuai pada browser atau perangkat Anda.
                            Bagaimanapun, harap diperhatikan bahwa jika Anda melakukan ini, Anda tidak dapat menggunakan fungsionalitas penuh
                            Platform atau Layanan kami.</p>
                        </ol>
                        
                    </section>


                    <hr className="divider"/>

                   
                    <section id="informasi">
                        <h2>Bagaimana kami menggunakan Informasi yang anda berikan kepada kami ?</h2>
                        <ul>
                            <p><span>7.1</span> Kami dapat mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah data pribadi Anda untuk satu atau lebih dari
                            tujuan-tujuan berikut ini:</p> 
                            <ol>
                                <li>
                                    mempertimbangkan dan/atau mengolah aplikasi/transaksi Anda dengan kami atau transaksi maupun komunikasi Anda dengan
                                    pihak ketiga melalui Layanan;
                                </li>
                                <li>mengelola, mengoperasikan, menyediakan dan/atau mengurus penggunaan dan/atau akses Anda ke Layanan kami dan Platform
                                kami (termasuk, namun tidak terbatas pada, mengingat preferensi anda), serta hubungan Anda dan akun pengguna Anda dengan
                                kami;</li>
                                <li>menanggapi, mengolah, berurusan dengan atau menyelesaikan transaksi, keluhan, dan/atau memenuhi permintaan Anda untuk
                                produk dan layanan tertentu serta memberi tahu Anda tentang masalah layanan dan tindakan akun yang tidak lazim;</li>
                                <li>memberlakukan Persyaratan Layanan atau perjanjian lisensi pengguna akhir apapun yang berlaku;</li>
                                <li>melindungi keselamatan pribadi dan hak, milik atau keselamatan pihak lainnya;</li>
                                <li>mempertahankan dan memberikan setiap pembaruan perangkat lunak dan/atau pembaruan lainnya serta dukungan yang mungkin
                                diperlukan dari waktu ke waktu untuk memastikan kelancaran Layanan kami;</li>
                                <li>menghubungi Anda atau berkomunikasi dengan Anda melalui panggilan telepon, pesan teks dan/atau pesan faks, email
                                dan/atau surat pos atau cara lainnya untuk tujuan mengurus dan/atau mengelola hubungan Anda dengan kami atau penggunaan
                                Layanan kami oleh Anda, seperti tetapi tidak terbatas pada mengomunikasikan informasi administratif kepada Anda yang
                                berkaitan dengan Layanan kami. Anda mengakui dan setuju bahwa komunikasi semacam itu oleh kami dapat dilakukan dengan
                                mengirimkan surat, dokumen atau pemberitahuan kepada Anda, yang dapat melibatkan pengungkapan data pribadi tertentu
                                tentang Anda untuk melakukan pengiriman tersebut serta tercantum juga pada sampul luar amplop/paket pos;</li>
                                <li>mengadakan kegiatan penelitian, analisis dan pengembangan (termasuk, tetapi tidak terbatas pada, analisis data, survei,
                                pengembangan produk dan layanan dan/atau pembuatan profil), untuk menganalisis bagaimana Anda menggunakan Layanan kami,
                                meningkatkan Layanan atau produk kami dan/atau meningkatkan pengalaman pelanggan Anda;</li>
                                <li>memungkinkan audit dan survei untuk, antara lain, memvalidasi ukuran dan komposisi audiens sasaran kami, serta memahami
                                pengalaman mereka dengan Layanan Bening's Skincare;</li>
                                <li>
                                    untuk secara otomatis membuat anda berlangganan newsletter Bening's Skincare setelah anda melakukan verifikasi atas
                                    alamat e-mail anda melalui OTP (yang mana apabila anda tidak berkenan, anda bisa langsung menekan tombol “berhenti
                                    berlangganan” yang dapat ditemukan di bagian bawah dari e-mail newsletter yang anda terima);
                                </li>
                                <li>
                                    menanggapi proses hukum atau mematuhi atau sebagaimana diwajibkan oleh setiap hukum, persyaratan pemerintah atau
                                    peraturan yang berlaku dengan yurisdiksi yang relevan atau jika kami memiliki keyakinan dengan niat baik bahwa
                                    pengungkapan tersebut diperlukan, termasuk, dengan tidak terbatas pada, memenuhi persyaratan untuk melakukan
                                    pengungkapan berdasarkan persyaratan hukum yang mengikat Bening's Skincare atau perusahaan terkait atau afiliasinya
                                    (termasuk, jika memungkinkan, tampilan nama Anda, detail kontak, dan detail perusahaan);
                                </li>
                                <li>
                                    menghasilkan statistik dan penelitian untuk pelaporan internal dan yang diwajibkan oleh hukum dan/atau persyaratan
                                    penyimpanan arsip;
                                </li>
                                <li>
                                    melaksanakan uji tuntas atau kegiatan penyaringan lainnya (termasuk, dengan tidak terbatas pada, pemeriksaan latar
                                    belakang) sesuai dengan kewajiban hukum atau peraturan atau prosedur manajemen risiko kami yang mungkin diwajibkan oleh
                                    hukum atau yang telah diberlakukan oleh kami;
                                </li>
                                <li>mengaudit Layanan kami atau bisnis Bening's Skincare;</li>
                            </ol><br/>
                            <p><span>7.2</span> Anda mengakui, menyetujui dan sepakat bahwa Bening's Skincare dapat mengakses, menyimpan dan mengungkapkan informasi dan
                            Konten Akun Anda jika diminta oleh hukum atau sesuai dengan perintah pengadilan atau oleh prioritas pemerintah atau
                            regulator yang memiliki yurisdiksi atas Bening's Skincare atau dengan keyakinan itikad baik bahwa penyimpanan atau
                            pengungkapan akses tersebut secara wajar diperlukan untuk: (a) mematuhi proses hukum; (b) memenuhi permintaan dari
                            otoritas pemerintah atau regulator manapun yang memiliki yurisdiksi atas Bening's Skincare; (c) menegakkan Persyaratan
                            Layanan Bening's Skincare atau Kebijakan Privasi ini; (d) menanggapi setiap klaim yang mengancam atau aktual terhadap
                            Bening's Skincare atau klaim lain bahwa suatu Konten melanggar hak pihak ketiga; (e) menanggapi permintaan Anda untuk
                            layanan pelanggan; atau (f) melindungi hak, properti atau keamanan pribadi Bening's Skincare, penggunanya dan/atau
                            publik.</p>

                        </ul>
                    </section>


            
                    <section id="melindungi">
                        <h2>Bagaimana Bening's skincare melindungi dan menarik informasi Pelanggan ?</h2>
                        <ol>
                            <li>
                                Kami menerapkan berbagai langkah pengamanan dan berusaha untuk memastikan keamanan data pribadi Anda di sistem kami.
                                Data pribadi pengguna berada di belakang jaringan yang aman dan hanya dapat diakses oleh sejumlah kecil karyawan yang
                                memiliki hak akses khusus ke sistem tersebut. Namun demikian, tidak adanya jaminan atau keamanan absolut tidak dapat
                                terhindarkan
                            </li>
                            <li>
                                Kami akan menyimpan data pribadi sesuai dengan Undang-Undang Privasi dan/atau hukum lain yang berlaku. Yaitu, kami akan
                                memusnahkan atau menganonimkan data pribadi Anda ketika kami secara wajar menganggap bahwa (i) tujuan data pribadi yang
                                dikumpulkan itu tidak lagi difungsikan oleh penyimpanan data pribadi tersebut; (ii) penyimpanan tidak lagi diperlukan
                                untuk tujuan hukum atau bisnis apa pun; dan (iii) tidak ada surat perintah yang melegitimasi untuk melakukan penarikan
                                data pribadi tersebut lebih jauh. Jika Anda berhenti menggunakan Platform kami, atau izin Anda untuk menggunakan
                                Platform dan/atau Layanan diakhiri atau ditarik kembali, kami dapat terus menyimpan, menggunakan dan/atau mengungkapkan
                                data pribadi Anda sesuai dengan Kebijakan Privasi dan kewajiban kami berdasarkan Undang-Undang Privasi. Tunduk terhadap
                                hukum yang berlaku, kami dapat secara aman membuang data pribadi Anda tanpa pemberitahuan sebelumnya kepada Anda.
                            </li>
                        </ol>
        
                    </section>

                    <hr className="divider"/>

                   
                    <section id="Informasi-anak">
                        <h2>Informasi tentang anak anak</h2>
                        <ol>
                            <li>
                                Layanan tidak ditujukan untuk anak-anak di bawah usia 13 tahun. Kami tidak secara sengaja mengumpulkan atau menyimpan
                                data pribadi atau informasi non-pribadi apapun dari siapa pun di bawah usia 13 tahun dan bagian apapun dari Platform
                                atau Layanan kami lainnya tidak ditujukan untuk anak-anak di bawah usia 13 tahun. Sebagai orang tua atau wali hukum yang
                                sah, mohon untuk tidak memberikan ijin bagi anak-anak yang berada di asuhan Anda untuk menyerahkan data pribadi kepada
                                Bening's Skincare. Dalam hal data pribadi dari anak-anak di bawah usia 13 tahun yang berada di bawah asuhan Anda dibuka
                                kepada Bening's Skincare, Anda dengan ini menyetujui pemrosesan data pribadi anak tersebut dan menerima serta menyetujui
                                untuk terikat pada Kebijakan Privasi ini atas nama anak tersebut. Kami akan menutup setiap akun yang digunakan secara
                                eksklusif oleh anak-anak tersebut dan akan mengeluarkan dan/atau menghapus setiap data pribadi yang kami yakini
                                dikirimkan tanpa persetujuan orang tua atau wali hukum yang sah oleh anak di bawah usia 13 tahun. memproses permintaan
                                Anda sesuai dengan Kebijakan Privasi ini dan kewajiban-kewajiban kami berdasarkan Undang-Undang Privasi dan hukum yang
                                berlaku lainnya. Bagaimanapun, pencabutan persetujuan Anda dapat berarti bahwa kami tidak akan dapat melanjutkan
                                menyediakan Layanan bagi Anda, dan kami mungkin harus mengakhiri hubungan dengan Anda dan/atau kontrak yang Anda buat
                                dengan kami.
                            </li>
                            <li>
                                Meminta Akses untuk, atau Pembetulan Data Pribadi
                                <ol>
                                    <li>
                                        Apabila Anda memiliki akun dengan kami, Anda dapat mengakses dan/atau membetulkan sendiri data pribadi Anda yang saat
                                        ini kami miliki atau kendalikan melalui halaman Pengaturan Akun di Platform. Apabila Anda tidak memiliki akun dengan
                                        kami, Anda dapat meminta untuk mengakses dan/atau membetulkan data pribadi Anda yang saat ini kami miliki atau
                                        kendalikan dengan mengirimkan permintaan tertulis kepada kami. Kami akan membutuhkan cukup informasi dari Anda untuk
                                        memastikan identitas Anda serta sifat permintaan Anda agar kami dapat menangani permintaan Anda. Oleh karenanya, harap
                                        mengajukan permintaan Anda secara tertulis dengan mengirim email kepada Personal Data Protection Officer (Petugas
                                        Perlindungan Data Pribadi) kami pada alamat email cs@beningspratamagrup.co.id
                                    </li>
                                    <li>
                                        Kami dapat juga membebankan biaya yang wajar kepada Anda untuk menangani dan memproses permintaan Anda untuk mengakses
                                        data pribadi Anda. Jika kami memilih untuk membebankan biaya, kami akan memberi Anda perkiraan biaya yang akan
                                        dibebankan secara tertulis. Harap diperhatikan, bahwa kami tidak wajib menanggapi atau mengurus permintaan akses Anda,
                                        kecuali Anda sudah setuju untuk membayar biayanya.
                                    </li>
                                    <li>
                                        Kami berhak menolak untuk mengoreksi data pribadi Anda sesuai dengan ketetapan sebagaimana diuraikan dalam Undang-Undang
                                        Privasi, dan bilamana diwajibkan dan/atau memberikan hak kepada organisasi untuk menolak mengoreksi data pribadi dalam
                                        keadaan yang dinyatakan.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                       

                       
                    </section>

                    <hr className="divider"/>

                
                    <section id="Pengakuan">
                        <h2>Pengakuan dan Persetujuan</h2>
                        <ol>
                            <li>
                                Dengan mengunjungi website ini, anda mengakui bahwa anda telah membaca dan memahami Kebijakan Privasi ini dan Ketentuan
                                Penggunaan dan setuju dan sepakat terhadap penggunaan, praktek, pemrosesan dan pengalihan informasi pribadi anda oleh
                                kami sebagaimana dinyatakan di dalam Kebijakan Privasi ini.
                            </li>
                            <li>
                                Anda juga menyatakan bahwa anda memiliki hak untuk membagikan seluruh informasi yang telah anda berikan kepada kami dan
                                untuk memberikan hak kepada kami untuk menggunakan dan membagikan informasi tersebut kepada Penyedia Layanan.
                            </li>
                        </ol>
                        
                    </section>

                    <hr className="divider"/>

                    <section id="faq">
                        <h2>FAQ</h2>
                        <p className="text-4">A FAQ is a list of frequently asked questions (FAQs) and answers on a
                            particular topic.</p>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="accordion accordion-alterate arrow-right" id="popularTopics">
                                <Accordion/>
                                </div>
                            </div>
                        </div>

                    </section>                                       
    
                </div>                                  
            </div>

            <footer id="footer" className="section bg-dark footer-text-light">
                    <div className="container">
                        <ul className="social-icons social-icons-lg social-icons-muted justify-content-center mb-3">
                            <li><a data-toggle="tooltip" href="/" target="_blank" title=""
                                    data-original-title="Twitter"><i className="fab fa-twitter"></i></a></li>
                            <li><a data-toggle="tooltip" href="/" target="_blank" title=""
                                    data-original-title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a data-toggle="tooltip" href="/" target="_blank" title=""
                                    data-original-title="Instagram"><i className="fab fa-instagram"></i></a></li>
                            
                        </ul>
                        <p className="text-center">Copyright &copy; 2023 <a
                                href="https://digital.flowmotion.co.id">Digital Flowmotion</a>. All
                            Rights Reserved.</p>
                        <p className="text-2 text-center mb-0">Design &amp; Develop by <a className="btn-link" rel="noreferrer" target="_blank"
                                href="https://digital.flowmotion.co.id">Digital Flowmotion</a>.</p>
                    </div>
            </footer>

            <a id="back-to-top" data-toggle="tooltip" title="Back to Top" href="#start"><i
            className="fa fa-chevron-up"></i></a>
        </div>

    </div>
  )
}


$(document).ready(function () {
  // Your jQuery code here

        // Dropdown show on hover
        $('.primary-menu ul.navbar-nav li.dropdown, .login-signup ul.navbar-nav li.dropdown').on("mouseover", function() {
            if ($(window).width() > 991) {
                $(this).find('> .dropdown-menu').stop().slideDown('fast');
                $(this).bind('mouseleave', function() {
                $(this).find('> .dropdown-menu').stop().css('display', 'none'); 
                });
            }
        });

        // When dropdown going off to the out of the screen.
        $('.primary-menu ul.navbar-nav .dropdown-menu').each(function() {
                var menu = $('#header .container-fluid').offset();
                var dropdown = $(this).parent().offset();

                var i = (dropdown.left + $(this).outerWidth()) - (menu.left + $('#header .container-fluid').outerWidth());

                if (i > 0) {
                    $(this).css('margin-left', '-' + (i + 5) + 'px');
                }
            });
        $(function () {
            $(".dropdown li").on('mouseenter mouseleave', function (e) {
                if ($(window).width() > 991) {
                    var elm = $('.dropdown-menu', this);
                    var off = elm.offset();
                    var l = off.left;
                    var w = elm.width();
                    var docW = $(window).width();
                    var isEntirelyVisible = (l + w + 30 <= docW);
                    if (!isEntirelyVisible) {
                        $(elm).addClass('dropdown-menu-right');
                    } else {
                        $(elm).removeClass('dropdown-menu-right');
                    }
                    }
            });
        });

        /*------------------------
            Side Navigation
        -------------------------- */

        $('#sidebarCollapse').on('click', function () {
            $('#sidebarCollapse span:nth-child(3)').toggleClass('w-50');
            $('.idocs-navigation').toggleClass('active');
        });


        
        /*------------------------
        Sections Scroll
        -------------------------- */

        $('.smooth-scroll,.idocs-navigation a').on('click', function() {
            event.preventDefault();
            var sectionTo = $(this).attr('href');
            $('html, body').stop().animate({
            scrollTop: $(sectionTo).offset().top - 120}, 1000, 'easeInOutExpo');
        });


        /*------------------------	
        Scroll to top
        -------------------------- */
        $(function () {
                $(window).on('scroll', function(){
                    if ($(this).scrollTop() > 400) {
                        $('#back-to-top').fadeIn();
                    } else {
                        $('#back-to-top').fadeOut();
                    }
                });
                });
        $('#back-to-top').on("click", function() {
            $('html, body').animate({scrollTop:0}, 'slow');
            return false;
        });
});

