// import logo from './logo.svg';
// import './App.css';
import HomePage from './pages/Home/HomePage';
import KebijakanPrivasi from './pages/kebijakan/KebijakanPrivasi';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
  
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/kebijakan" element={<KebijakanPrivasi/>} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
