import React, { Component } from 'react'

export class Accordion extends Component {
  render() {
    return (
      <div className="card">
            <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                PERTANYAAN, MASALAH ATAU KELUHAN? HUBUNGI KAMI
            </button>
            </h2>
           
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Apabila Anda memiliki pertanyaan atau masalah tentang praktik privasi kami mohon hubungi kami melalui e-mail di
                <a href="mail:to:care@beningspratamagrup.co.id"> care@beningspratamagrup.co.id</a>
                </div>
            </div>
        </div>
    )
  }
}

export default Accordion