import React from 'react'
import logo from '../../assets/Image/logo-bening.png'
import iosLogo from '../../assets/Image/Get-IOS.png'
import googleLogo from '../../assets/Image/google-play.png'
import Hp from '../../assets/Image/Image-5.png'
import './style.css'

export default function HomePage () {
  return (
    <div className="container-fluid">
        <div className="row mt-4">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 col-10 offset-1 mb-4">
                <img className="logo" src={logo} alt="logo-bening" width="30%"/>
                <div className="row mt-5">
                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8 col-10 tab1">
                        <a href="/" className="btn rounded-4">
                            <div className="row">
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4 text-end">
                                    <img className="ios" src={iosLogo} width="100%" alt=""/>
                                </div>
                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-7 col-8 mt-3 text-start">
                                    <p className="donlot">Download on the</p>
                                    <h1 className="font-ios">App Store</h1>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8 col-10 tab2">
                        <a href="/" className="btn rounded-4">
                            <div className="row">
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4 text-center">
                                    <img className="google" src={googleLogo} alt=""/>
                                </div>
                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8 mt-3 text-start">
                                    <p className="get">GET IT ON</p>
                                    <h1 className="font-google">Google Play</h1>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row mt-5 fs-3 fw-semibold">
                    <div className="col-xxl-10 col-lg-12 col-xl-12 col-md-12">
                    <p className="text"> "Download Bening's Apps sekarang! Aplikasi klinik kecantikan terbaik yang mempermudah jadwal perawatan, konsultasi dengan dokter, dan pembelian produk perawatan kulit. Dapatkan pengalaman perawatan yang lebih mudah dan efisien dengan Bening's Apps.".</p>
                    </div>
                </div>
                
                <div className="row mt-4">
                    <div className="col">
                        <a className="kebijakan" href="/kebijakan"><h5>Kebijakan Privasi</h5></a>
                            {/* <Link to="/kebijakan"><h5>Kebijakan Privasi</h5></Link> */}
                    </div>
                </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-12 col-lg-5 text-end col-12">
                
                <img className="hp" src={Hp} alt="gambar-hp" width="100%"/> 
           
            
            </div>
          
        </div>
    </div>
  )
}
